import { createTheme } from "@material-ui/core/styles"

export const primary = {
  50: "#edf6fa",
  100: "#d1e8f3",
  200: "#b3d9eb",
  300: "#95cae3",
  400: "#7ebedd",
  500: "#67b3d7",
  600: "#5facd3",
  700: "#54a3cd",
  800: "#4a9ac7",
  900: "#398bbe",
  A100: "#ffffff",
  A200: "#d6efff",
  A400: "#a3daff",
  A700: "#8ad0ff",
  contrastText: "#ffffff",
}

export const secondary = {
  50: "#e4e6eb",
  100: "#bcc2cc",
  200: "#9099ab",
  300: "#637089",
  400: "#41516f",
  500: "#203256",
  600: "#1c2d4f",
  700: "#182645",
  800: "#131f3c",
  900: "#0b132b",
  A100: "#6987ff",
  A200: "#365eff",
  A400: "#0335ff",
  A700: "#002ee8",
  contrastDefaultColor: "light",
}

const theme = createTheme({
  typography: {
    fontFamily: `Rubik`,
  },
  palette: {
    primary: primary,
    secondary: secondary,
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        h2: {
          fontSize: 24,
        },
        p: {
          fontSize: 17,
          color: "#5c6873",
          fontWeight: 300,
        },
        body: {
          backgroundColor: "#fff",
        },
        a: {
          color: "#5c6873",
          "&:hover": {
            color: primary[500],
          },
        },
      },
    },
    MuiButton: {
      root: {
        borderRadius: 20,
        fontWeight: 700,
      },
      sizeLarge: {
        borderRadius: 30,
        padding: "10px 32px",
        fontSize: 16,
      },
      contained: {
        backgroundColor: "#fff",
      },
    },
    MuiDialog: {
      paper: {
        margin: 16,
      },
      paperFullWidth: {
        width: "100%",
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.1)",
      },
    },
  },
})

export default theme
