import React from "react"
import PropTypes from "prop-types"
import { withStyles, Grid, Container } from "@material-ui/core"
import styles from "./style"
import { useLayout } from "../../utils/layoutContext"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link as ScrollLink } from "react-scroll"
import Language from "../language"

function Footer(props) {
  const { classes } = props
  const layout = useLayout()

  const renderSocialIcons = layout.footer_social.map((social, index) => {
    return (
      <Grid item key={index}>
        <a target="_blank" rel="noreferrer" href={social.link.url}>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            className={classes.socialLink}
          >
            <Grid item>
              <FontAwesomeIcon icon={["fab", social.icon_name]} />
            </Grid>
          </Grid>
        </a>
      </Grid>
    )
  })

  const renderLinks = layout.footer_navigation.map((navigation, index) => {
    return (
      <ScrollLink
        className={classes.navigationLink}
        key={index}
        to={navigation.scroll_to}
        smooth={true}
        offset={-70}
        duration={500}
      >
        {navigation.name.text}
      </ScrollLink>
    )
  })

  const renderContactLinks = layout.footer_contact_items.map(
    (contact, index) => {
      return (
        <a
          href={contact.link.url}
          target="_blank"
          rel="noreferrer"
          className={classes.contactLink}
          key={index}
        >
          <Grid container alignItems="center">
            <Grid item>
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                className={classes.socialLink}
              >
                <Grid item>
                  <FontAwesomeIcon icon={["fas", contact.icon_name]} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>{contact.text.text}</Grid>
          </Grid>
        </a>
      )
    }
  )

  return (
    <div className={classes.footer}>
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <img className={classes.logo} src={layout.logo1.url} alt="" />
            <p className={classes.description}>
              {layout.footer_short_description.text}
            </p>
            <Grid container>{renderSocialIcons}</Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <h4 className={classes.title}>
              {layout.footer_navigation_title.text}
            </h4>
            {renderLinks}
            <Language />
          </Grid>
          <Grid item xs={12} md={4}>
            <h4 className={classes.title}>
              {layout.footer_contact_title.text}
            </h4>
            {renderContactLinks}
          </Grid>
          <Grid item xs={12}>
            <div className={classes.copyrightText}>
              <a href={layout.wli_link.url} target={layout.wli_link.target}>
                {layout.wli_text}
              </a>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Footer)
