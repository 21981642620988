import React from "react"
import MuiSnackbar from "@material-ui/core/Snackbar"
import MuiAlert from "@material-ui/lab/Alert"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"

const Snackbar = ({ isOpen, onClose, message, variant }) => {
  if (!variant) {
    return (
      <MuiSnackbar
        open={isOpen}
        onClose={onClose}
        autoHideDuration={9000}
        message={message}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={onClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    )
  }

  return (
    <MuiSnackbar
      open={isOpen}
      onClose={onClose}
      autoHideDuration={9000}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={onClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      }
    >
      <MuiAlert variant="filled" severity={variant} onClose={onClose}>
        {message}
      </MuiAlert>
    </MuiSnackbar>
  )
}

export default Snackbar
