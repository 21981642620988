import React, { useEffect } from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import Sidebar from "../components/sidebar"
import ContactDialog from "../components/contact-dialog"
import { LayoutProvider } from "../utils/layoutContext"
import { RTLProvider } from "../utils/rtlContext"
import { StaticQuery, graphql } from "gatsby"
import { library } from "@fortawesome/fontawesome-svg-core"
import { fab } from "@fortawesome/free-brands-svg-icons"
import { fas } from "@fortawesome/free-solid-svg-icons"
import ThemeTopLayout from "gatsby-theme-material-ui-top-layout/src/components/top-layout"
import { withTheme } from "@material-ui/core"
import { create } from "jss"
import rtl from "jss-rtl"
import { StylesProvider, jssPreset } from "@material-ui/core/styles"

library.add(fab, fas)

const jssSettings = {
  plugins: [...jssPreset().plugins, rtl()],
}

const jss = create(jssSettings)

const query = graphql`
  query HeadersQuery {
    allPrismicLayout {
      edges {
        node {
          lang
          data {
            logo {
              url
            }
            logo1 {
              url
            }
            menu_items {
              name {
                text
              }
              link
            }
            action_button_text {
              text
            }
            footer_contact_items {
              text {
                text
              }
              link {
                link_type
                url
              }
              icon_name
            }
            footer_contact_title {
              text
            }
            footer_language_title {
              text
            }
            footer_languages {
              key
              language {
                text
              }
            }
            footer_navigation {
              scroll_to
              name {
                text
              }
            }
            footer_navigation_title {
              text
            }
            footer_short_description {
              text
            }
            footer_social {
              icon_name
              link {
                url
                link_type
              }
            }
            contact_dialog_title {
              text
            }
            contact_dialog_description {
              richText
            }
            contact_dialog_success_message {
              text
            }
            contact_dialog_email {
              text
            }
            contact_dialog_phone {
              text
            }
            contact_dialog_name {
              text
            }
            contact_dialog_message {
              text
            }
            contact_dialog_error_message {
              text
            }
            contact_dialog_branch {
              text
            }
            contact_dialog_branches {
              name {
                text
              }
              value
            }
            contact_dialog_cancel_button {
              text
            }
            contact_dialog_submit_button {
              text
            }
            contact_validation_branch_required
            contact_validation_email_invalid
            contact_validation_email_required
            contact_validation_message_required
            contact_validation_name_required
            contact_validation_phone_invalid
            wli_text
            wli_link {
              link_type
              url
              target
            }
          }
        }
      }
    }
  }
`

const Layout = ({ pageContext, children, theme }) => {
  useEffect(() => {
    theme.direction = pageContext.intl.language === "he" ? "rtl" : "ltr"
  }, [pageContext.intl.language])

  return (
    <StaticQuery
      query={query}
      render={(layout) => {
        const layouts = layout.allPrismicLayout.edges
        const selectedLayout = layouts.find(
          (layout) =>
            layout.node.lang === (pageContext.intl.language || "en-us")
        )

        return (
          <ThemeTopLayout theme={theme}>
            <StylesProvider jss={jss}>
              <RTLProvider
                value={pageContext.intl.language === "he" ? "rtl" : "ltr"}
              >
                <LayoutProvider value={selectedLayout.node.data}>
                  <Header />
                  <Sidebar />
                  <ContactDialog />
                  {children}
                  <Footer />
                </LayoutProvider>
              </RTLProvider>
            </StylesProvider>
          </ThemeTopLayout>
        )
      }}
    />
  )
}

export default withTheme(Layout)
