import React from "react"
import { IntlContextConsumer, changeLocale } from "gatsby-plugin-intl"
import styles from "./style"
import clsx from "clsx"
import { withStyles } from "@material-ui/core"
import { useLayout } from "../../utils/layoutContext"

const Language = ({ classes }) => {
  const layout = useLayout()

  return (
    <div className={classes.languageContainer}>
      <h4 className={classes.title}>{layout.footer_language_title.text}</h4>
      <IntlContextConsumer>
        {({ languages, language: currentLocale }) =>
          languages.map((language) => (
            <button
              key={language}
              onClick={() => {
                if (language !== currentLocale) {
                  changeLocale(language)
                }
              }}
              className={clsx(classes.language, {
                [classes.languageCurrent]: currentLocale === language,
              })}
            >
              {
                layout.footer_languages.find((lang) => lang.key === language)
                  .language.text
              }
            </button>
          ))
        }
      </IntlContextConsumer>
    </div>
  )
}

export default withStyles(styles)(Language)
