import React from "react"
import PropTypes from "prop-types"
import axios from "axios"
import {
  DialogContent,
  DialogActions,
  Button,
  TextField,
  CircularProgress,
  NativeSelect,
  InputLabel,
  FormControl,
  FormHelperText,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import styles from "./style"
import { useLayout } from "../../../utils/layoutContext"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import { PrismicRichText } from "@prismicio/react"
import { useGlobalState } from "../../../state"
import { useRTL } from "../../../utils/rtlContext"

const useStyles = makeStyles(styles)

function ContactFormContent(props) {
  const { handleClose } = props
  const classes = useStyles()
  const layout = useLayout()
  const rtlContext = useRTL()
  const [alert, setAlert] = useGlobalState("alert")

  const useFormClass = makeStyles({
    form: {
      "@global": {
        p: {
          textAlign: rtlContext === "rtl" ? "right" : "left",
        },
        label: {
          left: "unset",
        },
        svg:
          rtlContext === "rtl"
            ? {
                left: 0,
                right: "auto",
              }
            : {
                right: 0,
                left: "auto",
              },
      },
    },
  })

  const formClass = useFormClass()

  return (
    <>
      <Formik
        initialValues={{
          branch: "",
          name: "",
          email: "",
          phone: "",
          message: "",
        }}
        validationSchema={Yup.object({
          branch: Yup.string().required(
            layout.contact_validation_branch_required
          ),
          name: Yup.string().required(layout.contact_validation_name_required),
          email: Yup.string()
            .email(layout.contact_validation_email_invalid)
            .required(layout.contact_validation_email_required),
          phone: Yup.string().matches(
            /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im,
            layout.contact_validation_phone_invalid
          ),
          message: Yup.string().required(
            layout.contact_validation_message_required
          ),
        })}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          try {
            const formData = new FormData()
            for (let value in values) {
              formData.append(value, values[value])
            }
            await axios.post(
              `https://formspree.io/f/${
                values.branch === "jerusalem"
                  ? process.env.GATSBY_FORMSPREE_JERUSALEM_ID
                  : process.env.GATSBY_FORMSPREE_HAIFA_ID
              }`,
              formData
            )
            setAlert("success")
          } catch (err) {
            setAlert("error")
            console.error(err)
          } finally {
            handleClose()
            setSubmitting(false)
            resetForm()
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          isSubmitting,
          setFieldValue,
        }) => (
          <>
            <DialogContent>
              {isSubmitting ? (
                <div className={classes.loaderContainer}>
                  <CircularProgress />
                </div>
              ) : (
                <Form className={formClass.form}>
                  <div className={classes.description}>
                    <PrismicRichText
                      field={layout.contact_dialog_description.richText}
                    />
                  </div>
                  <FormControl
                    fullWidth
                    required
                    className={classes.formControl}
                    error={touched.branch && errors.branch}
                  >
                    <InputLabel id="select-branch">
                      {layout.contact_dialog_branch.text}
                    </InputLabel>
                    <NativeSelect
                      required
                      labelId="select-branch"
                      name="branch"
                      value={values.branch}
                      onBlur={handleBlur("branch")}
                      onChange={(e) => setFieldValue("branch", e.target.value)}
                    >
                      <option value="" />
                      {layout.contact_dialog_branches.map((branch) => (
                        <option key={branch.value} value={branch.value}>
                          {branch.name.text}
                        </option>
                      ))}
                    </NativeSelect>
                    <FormHelperText>{errors.branch}</FormHelperText>
                  </FormControl>
                  <TextField
                    value={values.name}
                    onBlur={handleBlur("name")}
                    onChange={handleChange("name")}
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                    margin="dense"
                    required
                    name="name"
                    label={layout.contact_dialog_name.text}
                    type="text"
                    fullWidth
                  />
                  <TextField
                    value={values.email}
                    onBlur={handleBlur("email")}
                    onChange={handleChange("email")}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                    margin="dense"
                    required
                    name="email"
                    label={layout.contact_dialog_email.text}
                    type="text"
                    fullWidth
                  />
                  <TextField
                    value={values.phone}
                    onBlur={handleBlur("phone")}
                    onChange={handleChange("phone")}
                    error={touched.phone && Boolean(errors.phone)}
                    helperText={touched.phone && errors.phone}
                    margin="dense"
                    name="phone"
                    label={layout.contact_dialog_phone.text}
                    type="text"
                    fullWidth
                  />
                  <TextField
                    value={values.message}
                    onBlur={handleBlur("message")}
                    onChange={handleChange("message")}
                    error={touched.message && Boolean(errors.message)}
                    helperText={touched.message && errors.message}
                    multiline
                    required
                    margin="dense"
                    name="message"
                    label={layout.contact_dialog_message.text}
                    type="text"
                    fullWidth
                    rows={3}
                  />
                  <DialogActions>
                    <Button type="button" onClick={handleClose}>
                      {layout.contact_dialog_cancel_button.text}
                    </Button>
                    <Button color="primary" type="submit">
                      {layout.contact_dialog_submit_button.text}
                    </Button>
                  </DialogActions>
                </Form>
              )}
            </DialogContent>
          </>
        )}
      </Formik>
    </>
  )
}

ContactFormContent.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default ContactFormContent
