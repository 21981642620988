const styles = (theme) => ({
  footer: {
    padding: "80px 0px",
    backgroundColor: "#262e3b",
    color: "#fff",
  },
  logo: {
    width: 120,
    marginBottom: 10,
  },
  description: {
    marginBottom: 20,
    color: "#fff",
  },
  socialLink: {
    color: "#fff",
    width: "38px",
    height: "38px",
    backgroundColor: theme.palette.primary[500],
    textDecoration: "none",
    marginInlineEnd: 15,
    borderRadius: "100%",
    "&:hover": {
      color: "#fff",
      backgroundColor: theme.palette.primary[700],
    },
  },
  title: {
    fontSize: 20,
    fontWeight: 700,
  },
  navigationLink: {
    display: "block",
    cursor: "pointer",
    color: "#fff",
    fontSize: 17,
  },
  contactLink: {
    color: "#fff",
    textDecoration: "none",
    display: "block",
    fontSize: 17,
    marginBottom: 15,
  },
  copyrightText: {
    marginTop: theme.spacing(6),
    textAlign: "center",
    "& a": {
      fontSize: 17,
      textDecoration: "none",
      color: "white",
    },
  },
})

export default styles
