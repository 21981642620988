import React, { useContext } from "react"

const RTLContext = React.createContext({})

export const RTLProvider = RTLContext.Provider
export const RTLConsumer = RTLContext.Consumer

export const useRTL = () => {
  return useContext(RTLContext)
}

export default RTLContext
