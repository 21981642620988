const styles = (theme) => ({
  menuItem: {
    width: 250,
  },
  link: {
    width: "100%",
    display: "block",
    padding: "8px 0px",
    textDecoration: "none",
    color: "#000",
    fontWeight: 600,
    cursor: "pointer",
    fontSize: 20,
  },
  drawer: {
    padding: "30px 30px",
    width: 230,
  },
})

export default styles
