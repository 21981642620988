const styles = (theme) => ({
  language: {
    color: "#fff",
    display: "block",
    marginTop: 5,
    fontSize: 17,
    cursor: "pointer",
    backgroundColor: "transparent",
    border: "none",
    padding: 0,
  },
  languageContainer: {
    marginTop: 30,
  },
  title: {
    fontSize: 20,
    fontWeight: 700,
  },
})

export default styles
