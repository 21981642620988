import { createStyles } from "@material-ui/core"

const styles = (theme) =>
  createStyles({
    description: {
      "@global": {
        p: {
          margin: 2,
          fontWeight: 300,
          fontSize: 16,
          color: "rgba(0, 0, 0, 0.54)",
        },
      },
    },
    loaderContainer: {
      textAlign: "center",
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(5),
    },
  })

export default styles
