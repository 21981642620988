import React from "react"
import PropTypes from "prop-types"
import { withStyles, Drawer } from "@material-ui/core"
import styles from "./style"
import { useLayout } from "../../utils/layoutContext"
import { useGlobalState } from "../../state"
import { Link } from "react-scroll"

function Sidebar(props) {
  const { classes } = props
  const layout = useLayout()
  const [sidebarOpen, setSidebarOpen] = useGlobalState("sidebar")

  const renderMenuItems = layout.menu_items.map((item, index) => {
    return (
      <Link
        key={index}
        className={classes.link}
        to={item.link}
        smooth={true}
        offset={-64}
        duration={500}
        onClick={() => setSidebarOpen(false)}
      >
        {item.name.text}
      </Link>
    )
  })

  return (
    <Drawer
      open={sidebarOpen}
      onClose={() => setSidebarOpen(false)}
      anchor={"right"}
    >
      <div className={classes.drawer}>{renderMenuItems}</div>
    </Drawer>
  )
}

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Sidebar)
