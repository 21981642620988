import React, { useState } from "react"
import PropTypes from "prop-types"
import {
  withStyles,
  Container,
  AppBar,
  Toolbar,
  Grid,
  IconButton,
  Hidden,
  Button,
} from "@material-ui/core"
import MenuIcon from "@material-ui/icons/Menu"
import styles from "./style"
import { useLayout } from "../../utils/layoutContext"
import { useGlobalState } from "../../state"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import { motion } from "framer-motion"
import { Link as ScrollLink } from "react-scroll"

const NewAppBar = motion(AppBar)

function Header(props) {
  const { classes } = props
  const layout = useLayout()
  const setSidebar = useGlobalState("sidebar")[1]
  const setContactDialog = useGlobalState("contactDialog")[1]
  const [scrollHeader, setScrollHeader] = useState(false)

  const openContactDialog = () => {
    setContactDialog(true)
  }

  const openSidebar = () => {
    setSidebar(true)
  }

  const linkVariants = {
    scroll: {
      color: "#000",
    },
    normal: {
      color: "#fff",
    },
  }

  const menuItems = layout.menu_items.map((menuItem, index) => (
    <ScrollLink
      key={index}
      activeClass={classes.activeLink}
      className={classes.scrollLink}
      to={menuItem.link}
      spy={true}
      smooth={true}
      offset={-64}
      duration={500}
    >
      <motion.span
        variants={linkVariants}
        transition={{ duration: 0.5 }}
        className={classes.link}
      >
        {menuItem.name.text}
      </motion.span>
    </ScrollLink>
  ))

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y < -150
      if (isShow !== scrollHeader) setScrollHeader(isShow)
    },
    [scrollHeader],
    false,
    false,
    300
  )

  const appBarVariants = {
    normal: {
      background: "rgba(255,255,255,0)",
      boxShadow: "0px 3px 5px 3px rgba(0, 0, 0, 0)",
    },
    scroll: {
      background: "rgba(255,255,255,1)",
      boxShadow: "0px 3px 5px 3px rgba(0, 0, 0, 0.2)",
    },
  }

  const logoVariants = {
    scroll: {
      width: 50,
      height: 50,
    },
    normal: {
      width: 90,
      height: 90,
    },
  }

  const logoLinkVariants = {
    scroll: {
      boxShadow: "0px 3px 5px 3px rgba(0, 0, 0, 0)",
    },
    normal: {
      boxShadow: "0px 3px 5px 3px rgba(0, 0, 0, 0.2)",
    },
  }

  return (
    <React.Fragment>
      <NewAppBar
        variants={appBarVariants}
        animate={scrollHeader ? "scroll" : "normal"}
        initial="normal"
        transition={{ duration: 0.5 }}
        position="fixed"
        className={classes.appBar}
      >
        <Toolbar disableGutters={false}>
          <Container>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <motion.div
                  transition={{ duration: 0.5 }}
                  variants={logoLinkVariants}
                  className={classes.logo}
                >
                  <ScrollLink
                    className={classes.logoLink}
                    to={"hero_video"}
                    smooth={true}
                    offset={-70}
                    duration={500}
                  >
                    <motion.img
                      transition={{ duration: 0.5 }}
                      variants={logoVariants}
                      alt="Hatikva Dental Logo"
                      className={classes.logoImage}
                      src={layout.logo.url}
                    />
                  </ScrollLink>
                </motion.div>
              </Grid>
              <Grid item>
                <Hidden smDown implementation="css">
                  {menuItems}
                </Hidden>
              </Grid>
              <Grid item>
                <Hidden mdUp implementation="css">
                  <IconButton onClick={openSidebar}>
                    <MenuIcon
                      className={
                        scrollHeader ? classes.menuIconBlack : classes.menuIcon
                      }
                    />
                  </IconButton>
                </Hidden>
                <Hidden smDown implementation="css">
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={openContactDialog}
                  >
                    {layout.action_button_text.text}
                  </Button>
                </Hidden>
              </Grid>
            </Grid>
          </Container>
        </Toolbar>
      </NewAppBar>
    </React.Fragment>
  )
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Header)
