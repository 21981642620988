import React from "react"
import PropTypes from "prop-types"
import { withStyles, Dialog, DialogTitle } from "@material-ui/core"
import styles from "./style"
import { useGlobalState } from "../../state"
import { useLayout } from "../../utils/layoutContext"
import ContactFormContent from "../_ui/contact-form-content"
import Snackbar from "../_ui/snackbar"

function ContactDialog() {
  const layout = useLayout()
  const [contactDialogOpen, setContactDialogOpen] =
    useGlobalState("contactDialog")
  const [alert, setAlert] = useGlobalState("alert")

  const handleClose = () => {
    setContactDialogOpen(false)
  }

  return (
    <>
      <Dialog
        open={contactDialogOpen}
        onClose={handleClose}
        maxWidth={"xs"}
        fullWidth
      >
        <DialogTitle id="form-dialog-title">
          {layout.contact_dialog_title.text}
        </DialogTitle>
        <ContactFormContent handleClose={handleClose} />
      </Dialog>
      <Snackbar
        isOpen={Boolean(alert)}
        variant={alert}
        message={
          Boolean(alert) && alert === "success"
            ? layout.contact_dialog_success_message.text
            : layout.contact_dialog_error_message.text
        }
        onClose={() => setAlert(null)}
      />
    </>
  )
}

ContactDialog.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(ContactDialog)
