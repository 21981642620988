import React, { useContext } from "react"

const LayoutContext = React.createContext({})

export const LayoutProvider = LayoutContext.Provider
export const LayoutConsumer = LayoutContext.Consumer

export const useLayout = () => {
  return useContext(LayoutContext)
}

export default LayoutContext
