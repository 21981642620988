const styles = (theme) => ({
  appBar: {
    backgroundColor: "transparent",
    boxShadow: "none",
  },
  logo: {
    height: "100%",
    textDecoration: "none",
    textTransform: "uppercase",
    backgroundColor: "#fff",
    padding: "8px 8px",
    borderBottomLeftRadius: 60,
    borderBottomRightRadius: 60,
    boxShadow: "0px 3px 5px 3px rgba(0, 0, 0, 0.2)",
    display: "flex",
  },
  logoImage: {
    display: "block",
  },
  link: {
    color: "#fff",
    margin: "0px 20px",
    textDecoration: "none",
    textTransform: "uppercase",
    fontWeight: 600,
    fontSize: 14,
  },
  headerSpacing: {
    ...theme.mixins.toolbar,
  },
  menuIcon: {
    color: "#fff",
  },
  menuIconBlack: {
    color: "#000",
  },
  scrollLink: {
    color: "#000",
    cursor: "pointer",
  },
  activeLink: {
    color: theme.palette.primary[500],
    "@global": {
      span: {
        color: `${theme.palette.primary[500]} !important`,
      },
    },
  },
  logoLink: {
    cursor: "pointer",
    display: "block",
  },
})

export default styles
